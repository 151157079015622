// Simplified Chinese
export const locale = {
  lang: 'zh-Hans',
  data: {
    MENU: {
      TASK_DETAIL: '任务详情',
      TASK_CONTENT: '任务内容',
      DASHBOARD: '报表',
      DASHBOARD_REP: '模块报表',
      DASHBOARD_TRA: '培训报表',
      DASHBOARD_REG: '法规报表',
      DASHBOARD_RIS: '风险报表',
      DASHBOARD_INC: '事故报表',
      DASHBOARD_FOOD: '食安报表',
      DASHBOARD_ASSESSMENT: '评估问题分析',
      DASHBOARD_ISSUE: '隐患及5S分析',
      DASHBOARD_INSPECTION_CORRECTION: '检查整改分析',
      DASHBOARD_TRAINING: '培训与证件报表',
      DASHBOARD_BI_SMALL: '工商业微小项目',
      DASHBOARD_HC_SMALL: '医疗业微小项目',
      DASHBOARD_PERFORMANCE: '绩效数据汇总表',
      WHITE_LIST: '白名单',
      FS_KPI: '餐饮服务 KPI',
      FMHC_KPI: '医疗业 KPI',
      FMBI_KPI: '工商业 KPI',
      ATP: 'ATP分析',
      DASHBOARD_HOMEPAGE: 'EHS绩效',
      DASHBOARD_CUSTOM_SAT: '医护满意度报表',
      KPI_EXEMPTION: 'KPI豁免',
      SYSTEM_SETUP: '系统设定',
      TAS110: '个人任务中心',
      TAS111: '任务管理',
      TAS112: '待认领任务',
      TRA100: '培训与证件',
      TRA110: '培训计划',
      TRA111: '培训日历',
      TRA112: '部门培训',
      TRA113: '教师管理',
      TRA114: '班级排课',
      TRA115: '培训日历',
      TRA116: '豁免工具',
      TRA117: '员工记录',
      TRA118: '课程记录',
      TRA119: '我的课程',
      TRA120: '证件管理',
      TRA121: '证件查询与录入',
      TRA122: '证件名称维护',
      TRA130: '培训记录',
      TRA131: '待完成培训',
      TRA132: '已完成培训',
      TRA133: '会议签到',
      TRA134: '我的课程',
      TRA135: '员工记录',
      TRA140: '课程管理',
      TRA141: '课程管理',
      TRA142: '试卷管理',
      TRA143: '试题集管理',
      TRA144: '培训计划',
      TRA150: '认证测评',
      TRA151: '认证测评成绩',
      TRA152: '认证测评管理',
      TRA153: '测评类型管理',
      RIS100: '风险评估',
      RIS110: '风险分析',
      RIS111: '风险矩阵',
      RIS112: '作业风险查询',
      RIS120: '风险控制',
      RIS121: '风险评估计划',
      RIS130: '风险记录',
      RIS131: '风险评估任务',
      RIS132: '整改措施',
      INC100: '事故管理',
      INC110: '事故处理',
      INC111: '事故快报',
      INC112: '事故查询',
      INC113: '事故调查',
      INC114: '整改措施',
      INC115: '工时列表',
      INC116: '工时详情',
      INC117: '受伤人员',
      INC120: '职灾统计',
      INC121: '职灾记录填报',
      INC122: '法定工作日记录',
      INC123: '职灾统计报表',
      INC124: '职灾记录填报表单',
      INC125: '消息中心',
      GR100: '法律法规',
      GR110: '法规资料库',
      GR111: '法规总览',
      GR112: '我的最爱',
      GR113: '公司适用清单',
      GR114: '上传更新法规',
      GR120: '鉴别与整改',
      GR121: '查核活动',
      GR122: '鉴别任务',
      GR123: '整改措施',
      SS100: '系统设定',
      SS110: '人员架构管理',
      SS111: '组织架构',
      SS112: '员工管理',
      SS113: '工厂管理',
      SS114: '员工群组',
      SS115: '公告管理',
      SS116: '角色管理',
      SS117: '岗位群组',
      SS120: '进阶管理',
      SS121: '系统代码',
      SS122: '角色管理',
      SS130: '表格管理',
      SS131: '题库',
      SS132: '表格',
      SS133: '整改措施',
      SS140: '空间管理',
      SS141: '空间管理',
      SS142: '空间类型管理',
      SS143: '空间群组管理',
      SS144: '满意度科室空间管理',
      SS145: '医护满意度调查月度汇总表',
      INS100: '检查与隐患',
      INS110: '检查管理',
      INS111: '隐患及5S上报',
      INS112: '计划检查',
      INS113: '立即检查',
      INS120: '隐患及5S管理',
      INS121: '查询结果',
      INS122: '隐患及5S查询',
      INS123: '整改措施查询',
      INS124: '隐患整改查询',
      INS125: '隐患及5S上报查询',
      CHA100:'变更管理',
      CHA110:'项目初始化',
      CHA120:'日常变更管理',
      CHA130:'变更查询',
      CHA140:'整改措施',
      TARGET_PLAN:'目标与计划',
      TARGET100:'目标管理',
      TARGET120:'目标进度',
      TARGET130:'目标设定',
      PLAN100:'计划管理',
      PLAN120:'计划及任务列表',
      PLAN130:'计划设定',
      PLAN140:'任务派发',
      TASKDISTRIBUTE:'任务派发',
      DISTRIBUTE100:'任务派发',
      NM100: '通知管理',
      NM111: '消息中心',
      MEDICAL_SERVICE: '医疗服务',
      ENV100: '环境服务',
      ENV110: '记录查询',
      ENV111: '空间检查查询',
      ENV112: '岗位评估查询',
      ENV113: '患者满意度查询',
      ENV114: '空间计划检查',
      ENV115: '空间查询报表',
      ENV116: '客户质量检查查询',
      DLV100: '运送陪护',
      DLV110: '记录查询',
      // DLV111: '空间检查查询',
      DLV111: '运送陪护服务检查查询',
      DLV112: '岗位评估查询',
      DLV113: '患者满意度查询',
      DLV114: '空间计划检查',
      EQP100: '设施服务',
      EQP110: '记录查询',
      EQP111: '岗位评估查询',
      EQP112: '设备检查查询',
      // EQP113: '空间检查查询',
      EQP113: '设施运维服务检查查询',
      EQP114: '患者满意度查询',
      EQP115: '空间计划检查',
      SQR100: '安保服务',
      SQR110: '记录查询',
      SQR111: '岗位评估查询',
      SQR112: '设备检查查询',
      // SQR113: '空间检查查询',
      SQR113: '空间安全检查查询',
      SQR114: '患者满意度查询',
      SQR115: '空间计划检查',
      SMR116: '医疗业报表',
      SMR117: '医疗业专业培训报表',
      SMR118: '患者满意度调查月度汇总表',
      SMR119: '岗位评估报表',
      SMR120: '看板数据',
      SMR121: '管理人员检查数据',
      QPE100: 'ASE与QPE',
      QPE110: 'QPE管理',
      QPE111: 'QPE上报',
      QPE112: 'QPE查询',
      QPE113: '计划检查',
      QPE114: 'QPE整改查询',
      QPE120: '项目自评管理',
      QPE121: '项目自评查询',
      QPE122: '项目自评整改查询',
      QPE130: 'ASE管理',
      QPE131: 'ASE上报',
      QPE132: 'ASE查询',
      QPE133: 'ASE整改查询',
      QPE134: 'ASE上传资料',
      DS100: '医护满意度',
      DS110: '医护满意度管理',
      DS111: '医护满意度查询',
      DS112: '二维码',
      DOC100: '合规文件管理',
      DOC110: '合规文件管理',
      DOC111: '合规文件管理',
      DOC112: '合规文件清单',
      DOC113: '合规文件分类',
      DOC114: '项目报告',
      SCS110: 'EHS积分',
      SCS111: '积分规则',
      SCS112: '积分查询',
    },
    GENERAL: {
      ACTIONS: {
        INVALID:'无效',
        REPORT:'上报',
        CHANGE_PASSWORD: '修改密码',
        LOGOUT: '注销',
        OK: '确认',
        CANCEL: '取消',
        CLOSE: '关闭',
        LEAVE: '离开',
        SUBMIT: '提交',
        RESET: '重置',
        SEARCH: '搜索',
        VIEW: '查看',
        ADD: '新增',
        EDIT: '编辑',
        EXPORT_ALL: '全部导出',
        EXPORT: '导出',
        DELETE: '删除',
        UPDATE: '更新',
        SAVE: '保存',
        DO_NOT_SAVE: '不保存',
        SAVE_CONFIRM: '保存并确认',
        SAVE_SUBMIT: '保存并提交',
        SAVE_LEAVE: '保存并离开',
        BACK: '返回',
        PREVIOUS: '上一步',
        NEXT: '下一步',
        NO: '否',
        YES: '是',
        CLEAR_SELECTION: '无',
        BACK_TO_LIST: '回列表页',
        PUBLISH: '发布',
        CHOOSE: '选择',
        CLEAR: '清除',
        ADD_NEW_VERSION: '发布',
        ADVANCED_SEARCH_SHOW: '显示高级搜索',
        ADVANCED_SEARCH_HIDE: '隐藏高级搜索',
        SUBMIT_PREVIEW: '提交预览',
        CLAIM: '认领',
        ASSIGN: '转派',
        EXPORT_EXCEL: '导出Excel',
        EXPORT_PDF: '导出pdf',
        DRAFT: '保存草稿',
        CLOSE_CASE: '结案',
        PRINT: '打印',
        REVOKE: '撤销',
        REJECT: '驳回',
        SHUTDOWN: '终止',
        END: '结束',
        CALCULATE: '回顾',
        ATTACHMENTS:'附件',
        REMARK:'备注'
      },
      TITLE: {
        DELETE: '确认删除',
        LEAVE: '确认离开?',
        SURE: '确认清除回报'
      },
      MSG: {
        DELETE: '删除后记录将会清空且无法复原，确定要删除？ ',
        LEAVE: '您尚未保存当前内容，请问您确定离开？ ',
        CONFIRM_LEAVE: '当前页面可能存在未保存内容，您确定离开吗？',
        SURE: '确认清空事故回报吗？ ',
        UNFILLED_FIELD: '无法保存，有尚未填写的输入框',
        UNFILLED_FIELD_SUBMIT: '无法提交，有尚未填写的输入框',
        FIELD_EMPTY: '必填项未填写，请补全',
        CREATE_SUCCESS: '添加成功',
        UPDATE_SUCCESS: '更新成功',
        DELETE_SUCCESS: '删除成功',
        INVALID_SUCCESS: '无效成功',
        SAVE_SUCCESS: '保存成功',
        SUBMIT_SUCCESS: '提交成功',
        PUBLISH_SUCCESS: '发布成功',
        REVOKE_SUCCESS: '撤销成功',
        CLOSE_SUCCESS: '终止成功',
      },
      NONE: '无',
      NO_RESULT: '没有结果',
      PLACEHOLDER: {
        SEARCH: '输入以搜索',
        SELECT: '请选择',
        CLARIFY: '请说明',
        INPUT: '请输入'
      },
      SELECT_REQUIRED: '请选择一项',
      FORM_REGION: '大项',
      FORM_SECTION: '分区',
      NO_ATTACHMENTS: '请添加附件！',
      POSITIVE: '是',
      NEGATIVE: '否',
    },
    LABEL: {
      CHECKBOX_SELECTED: '已选择'
    },
    DX: {
      NO_DATA_TEXT: '无数据',
      NO_DATA_TEXT_MEDICAL:'无逾期未完成数据',
      NO_DATA_TEXT_EMP:'无待培训员工数据',
      PAGER: {
        INFOTEXT: '第 {0} 页，共 {1} 页 (共 {2} 项)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: '选择附件',
        LABEL_TEXT: '或拖动附件至此',
        READY_MSG: '准备上传',
        MAX_FILE_SIZE: '附件大小限制',
        INVALID_MAX_FILE_SIZE_MSG: '附件过大',
        IGNORE_OVERLARGE_FILE_HINT: '过大的附件将不会上传',
        ONLY_PIC: '只能上传图片格式文件',
        RESET: '清除已选附件',
        DELETE: '删除附件',
        CONFIRM_DELETE: '您确定要删除此附件吗？ ',
        FAILED_TO_UPLOAD: '未能成功上传',
        IMPORT: '导入',
        PICTURE_HINT: '请上传jpg, jpeg, gif, png格式的图片,建议图片大小不超过2M',
        FILE_INVALID: '上传的文件不符合要求，请重新选择',
        INVALID_FILE_EXTENSION_MSG: '文件格式不符合要求',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: '每页',
        FIRST: '第一页',
        LAST: '最后一页',
        PREVIOUS: '上一页',
        NEXT: '下一页',
      },
    },
    HTTP_ERROR_MSG: {
      '401': '认证失效，请重新登陆',
      '404': '系统下线或维护中，请稍候再试',
      '413': '上传附件过大，请尝试较小附件',
      '504': '因导出数据量较大，导出数据无法及时返回，导出完成后消息中心会有提醒，请耐心等待。'
    }
  }
};
