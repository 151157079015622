export const SystemCodeMap = {
  countryList: '_SYS_A1',
  corporateStructureTypeList: '_SYS_A8',
  corporateStructureType: {
    masterCompany: '_SYS_A8_0',
    company: '_SYS_A8_1',
    department: '_SYS_A8_2',
    division: '_SYS_A8_3',  // 大区
    region: '_SYS_A8_4',    // 区域
    group: '_SYS_A8_5',     // 项目
    branchCompany: '_SYS_A8_6',
    statisticsOfWaste: '_SYS_A8_7',
    headquarters: '_SYS_A8_8'
  },
  inspectionType: '_SYS_BL',
  changeType:'_SYS_PC',
  regionNameList: '_SYS_BK',
  trainingClassification: '_TRAINING_AM',
  trainingScheduleType: '_TRAINING_PLAN_LIST',
  trainingScheduleTypeList: {
    bySelection: '_TRAINING_PLAN_LIST_A',
    byJobs: '_TRAINING_PLAN_LIST_B',
    byRoles: '_TRAINING_PLAN_LIST_C',
    byRoleLevel: '_TRAINING_PLAN_LIST_D',
  },
  lessonSchedulingStatus: '_TRAINING_PLAN_STATUS',
  lessonSchedulingStatusList: {
    pending: '_TRAINING_PLAN_STATUS_A',
    live: '_TRAINING_PLAN_STATUS_B',
    paused: '_TRAINING_PLAN_STATUS_C',
    closed: '_TRAINING_PLAN_STATUS_D',
    completed: '_TRAINING_PLAN_STATUS_E'
  },
  trainingMethod: '_TRAINING_AN',
  maintenanceDept: '_TRAINING_AO',
  trainingStatusList: '_TRAINING_AK',
  recordStatus: {
    pending: '_TRAINING_AK_1',
    overTime: '_TRAINING_AK_2',
    completed: '_TRAINING_AK_3',
    overFinished: '_TRAINING_AK_5'
  },
  trainingTypeList: '_TRAINING_AL',
  classStatusList: '_TRAINING_AG',
  classStatus: {
    pending: '_TRAINING_AG_1',
    live: '_TRAINING_AG_2',
    completed: '_TRAINING_AK_3',
    aboutToStart: '_TRAINING_AG_4',
    processing: '_TRAINING_AG_5'
  },
  instructorTypeList: '_TRAINING_AE',
  instructorType: {
    fullTimeEmployee: '_TRAINING_AE_1',
    contractor: '_TRAINING_AE_2',
    thirdParty: '_TRAINING_AE_3'
  },
  lessonTrainingTypeList: '_TRAINING_AC',
  lessonTypeList: '_TRAINING_AD',
  locationType: {
    classroom: '_SYS_AB_11'
  },
  statusList: '_SYS_A9',
  status: {
    active: '_SYS_A9_1',
    inactive: '_SYS_A9_2',
    pending: '_SYS_A9_3',
    readOnly: '_SYS_A9_4',
  },
  inspectionStatusList: '_SYS_B2',
  inspectionStatus: {
    draft: '_SYS_B2_5',
    temp: '_SYS_B2_7'
  },
  timeUnitList: '_SYS_AW',
  gender: '_SYS_A3',
  // jobGrade: '_SYS_AP',
  lessonType: '_TRAINING_AD',
  trainingClassStatus: '_TRAINING_AG',
  timeUnit: {
    hour: '_SYS_AW_1',
    day: '_SYS_AW_2',
    week: '_SYS_AW_3',
    month: '_SYS_AW_4',
    year: '_SYS_AW_5',
    once: '_SYS_AW_6',
  },
  conferenceStatus: '_TRAINING_SI',
  conferenceStatusList: {
    pending: '_TRAINING_SI_1',
    live: '_TRAINING_SI_2',
    closed: '_TRAINING_SI_3',
  },
  trainingAttendanceStatusList: '_TRAINING_AH',
  trainingAttendanceStatus: {
    incomplete: '_TRAINING_AH_1',
    complete: '_TRAINING_AH_2',
    exempt: '_TRAINING_AH_3',
    expired: '_TRAINING_AH_4',
    pass: '_TRAINING_AH_5',
    fail: '_TRAINING_AH_6',
    unrecord: '_TRAINING_AH_7'
  },
  reasonList: '_TRAINING_AI',
  reason: {
    onleave: '_TRAINING_AI_1',
    other: '_TRAINING_AI_2'
  },
  trainingFinishStatus: '_TRAINING_PA',
  riskRatingList: '_SYS_RR',
  riskRating: {
    hight: '_SYS_RR_1',
    medium: '_SYS_RR_2',
    low: '_SYS_RR_3',
    negligible: '_SYS_RR_4',
    lowMedium: '_SYS_RR_5',
    highMedium: '_SYS_RR_6',
    extreme: '_SYS_RR_7'
  },
  riskParametersList: '_SYS_R1',
  riskParameters: {
    likelyhood: '_SYS_R1_1',
    severity: '_SYS_R1_2'
  },
  assessmentTypeList: '_SYS_R2',
  assessmentType: {
    hazardIdentificationAndRiskAssessment: '_SYS_R2_1',
    workplaceHazardAnalysis: '_SYS_R2_2',
    jobHazardAnalysis: '_SYS_R2_3'
  },
  assessmentModeList: '_SYS_R3',
  assessmentMode: {
    FR: '_SYS_R3_1',
    SR: '_SYS_R3_2',
    numberOfIncident: '_SYS_R3_3',
    others: '_SYS_R3_4',
  },
  correctiveActionTaskStatusList: '_SYS_CA',
  correctiveActionTaskStatus: {
    confirmed: '_SYS_CA_1',
    unconfirmed: '_SYS_CA_2',
  },
  jobTaskTypeList: '_SYS_JT1',
  jobTaskType: {
    Administration: '_SYS_JT1_1',
    Inspection: '_SYS_JT1_2',
    businessTrip: '_SYS_JT1_3',
    training: '_SYS_JT1_4',
    other: '_SYS_JT1_5'
  },
  jobTaskSubTypeList: '_SYS_JT2',
  jobTaskLocationList: '_SYS_JT3',
  jobTaskServiceLifeCycleList: '_SYS_JT4',
  jobTaskStaffTypeList: '_SYS_JT5',
  jobTaskStaffType: {
    employee: '_SYS_JT5_A',
    // passenger: '_SYS_JT5_B',
    contractor: '_SYS_JT5_C',
    outsourcer: '_SYS_JT5_D',
    visitor: '_SYS_JT5_E',
    // employeeFamily: '_SYS_JT5_F',
    otherStakeholder: '_SYS_JT5_G'
  },
  jobRank: '_SYS_WH_11',
  employeeType: '_SYS_WH_10',
  employeeStatus: '_SYS_BV',
  employeeStatusList: {
    on: '_SYS_BV_1',
    off: '_SYS_BV_2',
  },
  jobTaskCycleList: '_SYS_JT6',
  jobTaskEnvironmentList: '_SYS_JT7',
  jobTaskToolList: '_SYS_JT8',
  jobTaskMaterialList: '_SYS_JTE',
  jobTaskQualificationList: '_SYS_JTF',
  jobTaskSituationList: '_SYS_JT9',
  jobTaskEngineeringControlList: '_SYS_JT10',
  jobTaskManagementControlList: '_SYS_JT11',
  jobTaskPersonalProtectiveEquipmentList: '_SYS_JT12',
  jobTaskLocationOther: '_SYS_JT3_Other',
  jobTaskEnvironmentOther: '_SYS_JT7_Other',
  jobTaskToolOther: '_SYS_JT8_Other',
  jobTaskMaterialOther: '_SYS_JTE_Other',
  jobTaskQualificationOther: '_SYS_JTF_Other',
  jobTaskEngineeringControlOther: '_SYS_JT10_Other',
  jobTaskManagementControlOther: '_SYS_JT11_Other',
  jobTaskPersonalProtectiveEquipmentOther: '_SYS_JT12_Other',
  jobTaskUpdateFromList: '_SYS_JT13',
  jobTaskUpdateFrom: {
    correctiveAction: '_SYS_JT13_1',
    conductJobHazardAnalysis: '_SYS_JT13_2',
    jobTaskSetup: '_SYS_JT13_3'
  },
  hazardTypeList: '_SYS_SO_1',
  hazardType: {
    commutingRoadAccident: '_SYS_SO_1_AU', // 上下班公路交通事故
    commutingRailwayTrafficAccident: '_SYS_SO_1_AV', // 上下班鐵路交通事故
    commutingCabinAircraftTrafficAccident: '_SYS_SO_1_AW', // 上下班船艙、航空器交通事故
    commutingOtherTrafficAccident: '_SYS_SO_1_AX', // 上下班其他交通事故
    nonCommutingRailwayTrafficAccident: '_SYS_SO_1_AY', // 非上下班鐵路交通事故
    nonCommutingRoadAccident: '_SYS_SO_1_AZ', // 非上下班公路交通事故
    nonCommutingCabinAircraftTrafficAccident: '_SYS_SO_1_BA', // 非上下班船艙、航空器交通事故
    nonCommutingOtherTrafficAccident: '_SYS_SO_1_BB', // 非上下班其他交通事故
  },
  hazardTypeOther: '_SYS_SO_1_Other',
  scheduleTypeList: '_SYS_AV',
  scheduleType: {
    inspectionScheduling: '_SYS_AV_1',
    auditScheduling: '_SYS_AV_2',
    incidentScheduling: '_SYS_AV_3',
    scheduledInspection: '_SYS_AV_5',
  },
  activityStatusList: '_SYS_AY',
  activityStatus: {
    completed: '_SYS_AY_4',
    pending: '_SYS_AY_5',
    live: '_SYS_AY_6',
    discontinued: '_SYS_AY_7'
  },
  taskStatusList: '_SYS_PL1',
  taskStatus: {
    inProgress: '_SYS_PL1_1', // 進行中
    completed: '_SYS_PL1_2', // 已结束
    cancelled: '_SYS_PL1_3', // 撤銷
    pending: '_SYS_PL1_4', // 待定
    verifying: '_SYS_PL1_5', // 驗證中
    signing: '_SYS_PL1_6', // 簽核中
    caseClosed: '_SYS_PL1_7', // 結案
    draft: '_SYS_PL1_8', // 草稿
    signed: '_SYS_PL1_9', // 已簽核
    unsigned: '_SYS_PL1_A', // 未簽核
    reporting: '_SYS_PL1_B', // 通報中
    reviewing: '_SYS_PL1_C', // 受理中
    underInvestigation: '_SYS_PL1_D', // 調查中
    underImprovement: '_SYS_PL1_E', // 整改中
    waiting: '_SYS_PL1_F', // 接受中
    overdue: '_SYS_PL1_G', // 逾期
    assigning: '_SYS_PL1_H', // 指派中
    review: '_SYS_PL1_I', // 審核中
    finalReview: '_SYS_PL1_J', // 最終審核
    close: '_SYS_PL1_T1', // 关闭中
    invalid: '_SYS_PL1_T2', // 无效
    projectInit:'_SYS_PC_1',//项目初始化
    dailyChange:'_SYS_PC_2',//日常变更管理
  },
  planningListFrequencyList: '_SYS_TM_PD',
  planningListFrequency: {
    direct: '_SYS_TM_PD_0',
    hourly: '_SYS_TM_PD_1',
    daily: '_SYS_TM_PD_2',
    weekly: '_SYS_TM_PD_3',
    monthly: '_SYS_TM_PD_4',
    yearly: '_SYS_TM_PD_5',
    once: '_SYS_TM_PD_6',
  },
  endDateTypeList: '_SYS_PL',
  endDateType: {
    none: '_SYS_PL_1',
    byDate: '_SYS_PL_2',
    afterRecurrences: '_SYS_PL_3',
  },
  dailyRecurrenceList: '_SYS_PL2',
  dailyRecurrence: {
    once: '_SYS_PL2_1',
    hour: '_SYS_PL2_2',
    minute: '_SYS_PL2_3'
  },
  monthlyRecurrenceList: '_SYS_PL3',
  monthlyRecurrence: {
    first: '_SYS_PL3_1',
    second: '_SYS_PL3_2',
    third: '_SYS_PL3_3',
    forth: '_SYS_PL3_4',
    last: '_SYS_PL3_5',
    day: '_SYS_PL3_6',
  },
  weekdaysList: '_SYS_PL4',
  weekdays: {
    monday: '_SYS_PL4_1',
    tuesday: '_SYS_PL4_2',
    wednesday: '_SYS_PL4_3',
    thursday: '_SYS_PL4_4',
    friday: '_SYS_PL4_5',
    saturday: '_SYS_PL4_6',
    sunday: '_SYS_PL4_7',
    weekday: '_SYS_PL4_8'
  },
  taskTypeList: '_SYS_ST3',
  safetyKPIList: '_SYS_PL1',
  deviationSourceList: '_SYS_PL1',
  deviationCategoryList: '_SYS_PL1',
  taskTypes: {
    // 風險與評估
    riskAssessment: '_SYS_ST3_1',
    investigation: '_SYS_ST3_2',
    // 事故管理
    incidentManagement: '_SYS_ST3_3',
    incidentInvestigation: '_SYS_ST3_4',
    correctiveAction: '_SYS_ST3_5',
    // 法規鑑別
    regulationIdentification: '_SYS_ST3_6',
    regulationDistributedIdentification: '_SYS_ST3_7',
    regulationCorrectiveAction: '_SYS_ST3_8',
    // 檢查管理
    reportedIssue: '_SYS_ST3_9',
    scheduledInspection: '_SYS_ST3_A',
    nonScheduledInspection: '_SYS_ST3_B',
    inspectionCorrectiveAction: '_SYS_ST3_C',
    issueCorrectiveAction: '_SYS_ST3_D',
    // 二期医疗
    spaceInspection: '_SYS_ST3_E',
    // satisfaction: '_SYS_ST3_G',
    qpeInspectionAction: '_SYS_ST3_E21',
    qpeInspection: '_SYS_ST3_E2',
    reviewQpeInspection: '_SYS_ST3_E22',
    selfAssessment: '_SYS_ST3_G',
    selfAssessmentCorrection: '_SYS_ST3_G1',
    aseInspectionAction: '_SYS_ST3_H1',
    aseInspection: '_SYS_ST3_H',
    reviewAseInspection: '_SYS_ST3_H2',
    changeInitAction:'_SYS_PC_1',
    changeDailyAction:'_SYS_PC_2',
    changeAction:'_SYS_ST3_P',
    changeVerify:'_SYS_ST3_P_P1',
    taskDistribute:'_SYS_ST3_T',//任务派发
  },
  responsibilityTypeList: '_SYS_ST1', // 負責人員類型
  responsibilityTypes: {
    taskManager: '_SYS_ST1_1', // 任務負責人
    evaluationParticipant: '_SYS_ST1_2', // 評估參與者
    evaluationTeam: '_SYS_ST1_3', // 評論團隊
    proxySigner: '_SYS_ST1_4', // 簽核代理人
    signer: '_SYS_ST1_5', // 簽核人員
    creator: '_SYS_ST1_6', // 發起人
    assignee: '_SYS_ST1_7', // 執行者
    reviewTeam: '_SYS_ST1_8', // 評論人員
    verifier: '_SYS_ST1_9', // 驗證人
    // parallelSigner: '_SYS_ST1_A', // 平行傳會簽核人員
    // parallelProxySigner: '_SYS_ST1_B', // 平行傳會簽核代理人
    incidentReportCreatedBy: '_SYS_ST1_C', // 事故通報人
    incidentInvolvedPerson: '_SYS_ST1_D', // 事故當事人
  },
  reportStatusList: '_SYS_CF',
  reportStatus: {
    confirmed: '_SYS_CF_1',
    unconfirmed: '_SYS_CF_2'
  },
  riskOpportunitySourceList: '_SYS_R4',
  riskOpportunityRiskTreatmentList: '_SYS_R5',
  riskModuleList: '_SYS_MO',
  taskActionHistoryList: '_SYS_ST4',
  // correctiveActionTypeList: '_SYS_BA',
  taskActionHistory: {
    claimTask: '_SYS_ST4_7'
  },
  correctiveActionTypeList: '_SYS_BA',
  logTypeList: '_SYS_A7',
  // reportEntityList: '_SYS_RSK_R',
  logicAnswerTypeList: '_SYS_AM',
  logicAnswerType: {
    yes: '_SYS_AM_1',
    no: '_SYS_AM_2',
    unknown: '_SYS_AM_3',
  },
  incidentFoodPeriod: '_SYS_BM',
  incidentFoodPossibleSource: '_SYS_BN',
  incidentFoodClassification: '_SYS_BO',
  incidentSeverity: '_SYS_WH_1', // 事故严重程度
  incidentTypeLists: '_SYS_WH_2_1',  // 事件类型
  safetyIncidents: '_SYS_WH_2_2', // 食品安全事件类型
  incidentSeverityList: {
    almost: '_SYS_WH_1_5',
    mild: '_SYS_WH_1_2',
    normal: '_SYS_WH_1_3',
    serious: '_SYS_WH_1_4',
  },
  trainingRange: '_SYS_WH_28',
  // _SYS_WH_2
  incidentClassification: '_SYS_WH_3', // 事故分类
  injuredArea: '_SYS_WH_4', // 受伤部位
  // insurance: '_SYS_WH_5', // 保险情况
  insurance : '_SYS_WH_9', // 保险情况
  rootcause: '_SYS_WH_6', // 根本原因
  spaceRootcause: '_SYS_WH_18', // 根本原因
  causeEmp: '_SYS_PA_1',
  dutyList: '_INCIDENT_D1',
  duty: {
    involvedPerson: '_INCIDENT_D1_5',
    newNotifier: '_INCIDENT_D1_6',
  },
  incidentInvestigationStatusList: {
    inProgress: '_SYS_PL1_1',
    signing: '_SYS_PL1_6',
    caseClosed: '_SYS_PL1_7',
    invalid: '_SYS_PL1_J'
  },
  incidentTypeList: '_INCIDENT_D2',
  incidentType: {
    // occupationalAccident: '_INCIDENT_D2_1', // 職業災害
    accident: '_INCIDENT_D2_2', // 一般事故
    nearMiss: '_INCIDENT_D2_3', // 虛驚事件
    filing: '_INCIDENT_D2_4' // 存參
  },
  // weatherOption: '_report_weather_A1',
  injurySeverity: '_report_injured_A2',
  transportationList: '_INCIDENT_TP', // 交通工具
  transportation: {
    car: '_INCIDENT_TP_1',
    locomotive: '_INCIDENT_TP_2',
    bicycle: '_INCIDENT_TP_3',
    walk: '_INCIDENT_TP_4',
    pubicTransportation: '_INCIDENT_TP_5',
  },
  lossTypeList: '_INCIDENT_DA',
  lossType: {
    otherLoss: '_INCIDENT_DA_1', // 其他損失
    facilityLoss: '_INCIDENT_DA_2', // 器材損失
  },
  restrictionTypeList: '_INCIDENT_DE',
  // lossTypeClassification: '_INCIDENT_DD',
  partOfBodyAffectedList: '_report_injured_A1',
  partOfBodyAffected: {
    other: '_report_injured_A1_Other'
  },
  indirectCauseList: '_INCIDENT_D3',
  unsafeConditionList: '_INCIDENT_D3_1',
  unsafeConditionOther: '_INCIDENT_D3_1_Other',
  unsafeBehaviorList: '_INCIDENT_D3_2',
  rootCauseList: '_INCIDENT_D4',
  rootCauseOther: '_INCIDENT_D4_Other',
  reportTypeList: '_INCIDENT_D5',
  reportType: {
    // personalReport: '_INCIDENT_D5_1',
    // occupationalAccident: '_INCIDENT_D5_2',
    abnormalEventReport: '_INCIDENT_D5_3',
  },
  incidentCategoryList: '_INCIDENT_D6',
  incidentCategoryOther: '_INCIDENT_D6_Other',
  incidentCategoryChemicalRelease: '_INCIDENT_D6_Chemical_Release',
  // incidentLossTimeClassificationList: '_INCIDENT_D6_3',
  // workingPlaceList: '_INCIDENT_D7_1',
  // notWorkingPlaceList: '_INCIDENT_D7_2',
  involvedTypeList: '_INCIDENT_D8',
  reportingLevelList: '_INCIDENT_D9',
  signActionList: '_SYS_TS2',
  signAction: {
    ok: '_SYS_TS2_1',
    reject: '_SYS_TS2_2'
  },
  parallelSignActionList: '_SYS_PL6',
  parallelSignAction: {
    ok: '_SYS_PL6_1',
    reject: '_SYS_PL6_2',
    processing: '_SYS_PL6_3',
    reassignTo: '_SYS_PL6_4'
  },
  taskVersionContentList: '_INCIDENT_HS_D',
  taskVersionUpdateUpdateFromList: '_INCIDENT_HS_UF',
  violationList: '_INCIDENT_DF',
  violation: {
    regulatoryRequirement: '_INCIDENT_DF_1', // 法規
    OSHM: '_INCIDENT_DF_2', // 職安衛室管理系統規範
    companyRules: '_INCIDENT_DF_3', // 公司規定
    other: '_INCIDENT_DF_4', // 可能造成其他潛在危害
    noViolation: '_INCIDENT_DF_5', // 無違反
  },
  verificationNextActionList: '_INCIDENT_DG',
  verificationNextAction: {
    reject: '_INCIDENT_DG_1',
    postpone: '_INCIDENT_DG_2',
  },
  laborInsuranceUnderwritingResultList: '_INCIDENT_DB',
  disabilityInjuryTypeList: '_INCIDENT_DC',
  corporateStructureContactsTitleList: '_SYS_CB',
  corporateStructureContactsTitleDelList: '_SYS_CB',
  corporateStructureContactsTitleOneList: '_SYS_CB',
  orgTypeList: '_SYS_A12',
  shiftCodeList: '_SYS_AQ',
  whCodeList: '_SYS_WH_8',
  regulationApplicableStatusList: '_SYS_IR_AS',
  regulationApplicableStatus: {
    applicable: '_SYS_IR_AS_1',
    notApplicable: '_SYS_IR_AS_2',
    notIdentified: '_SYS_IR_AS_4',
  },
  regulationActivityStatusList: '_SYS_IR_AT',
  regulationActivityStatus: {
    waiting: '_SYS_IR_AT_1', // 即將開始
    progressing: '_SYS_IR_AT_2', // 進行中
    completed: '_SYS_IR_AT_3', // 完成
    overdue: '_SYS_IR_AT_4', // 逾期
    canceled: '_SYS_IR_AT_5', // 終止
  },
  regulationStepStatusList: '_SYS_IR_SS',
  regulationStepStatus: {
    none: '_SYS_IR_SS_0',
    current: '_SYS_IR_SS_1',
    processed: '_SYS_IR_SS_2',
  },
  regulationLogTypeList: '_SYS_IR_LG',
  regulationLogType: {
    activity: '_SYS_IR_LG_1',
    acceptTask: '_SYS_IR_LG_2',
    forwardTask: '_SYS_IR_LG_3',
    submit: '_SYS_IR_LG_4',
    approve: '_SYS_IR_LG_5',
    reject: '_SYS_IR_LG_6',
    assignCorrective: '_SYS_IR_LG_7',
    acceptCorrective: '_SYS_IR_LG_8',
    notify: '_SYS_IR_LG_9',
  },
  regulationTaskStatusList: '_SYS_IR_ST',
  regulationTaskStatus: { // 鑑別任務
    waiting: '_SYS_IR_ST_1', // 接受中
    toAssign: '_SYS_IR_ST_11', // 指派中
    improving: '_SYS_IR_ST_12', // 整改中
    identifying: '_SYS_IR_ST_2', // 鑑別中
    reviewing: '_SYS_IR_ST_3', // 審核中
    completed: '_SYS_IR_ST_4', // 完成
    canceled: '_SYS_IR_ST_5', // 取消
  },
  regulationCallOutStatusList: '_SYS_IR_CO',
  regulationCallOutStatus: { // 分工鑑別
    identifying: '_SYS_IR_CO_1', // 鑑別中
    completed: '_SYS_IR_CO_2', // 完成
    overdue: '_SYS_IR_CO_3', // 逾期
    canceled: '_SYS_IR_CO_4',
  },
  regulationPassStatusList: '_SYS_IR_PS',
  regulationPassStatus: {
    passed: '_SYS_IR_PS_1',
    notPassed: '_SYS_IR_PS_2',
    notIdentified: '_SYS_IR_PS_4'
  },
  regulationContentCategoryList: '_SYS_IR_IC',
  regulationContentCategory: {
    last: '_SYS_IR_IC_1',
    current: '_SYS_IR_IC_2',
    callOut: '_SYS_IR_IC_3',
  },
  regulationImprovementCategoryList: '_SYS_IR_CC',
  regulationImprovementCategory: {
    immediate: '_SYS_IR_CC_1',
    postponed: '_SYS_IR_CC_2',
  },
  formManagementTypeList: '_SYS_FT',
  questionBankQuestionTypeList: '_SYS_AN',
  questionBankQuestionType: {
    safety: '_SYS_AN_1', // 安全
    environmental: '_SYS_AN_2', // 環境
    other: '_SYS_AN_3', // 其他
  },
  questionBankResponseTypeList: '_SYS_AK',
  questionBankResponseType: {
    shortText: '_SYS_AK_1', // 短文本
    longText: '_SYS_AK_2', // 長文本
    numeric: '_SYS_AK_3', // 數字
    logical: '_SYS_AK_7', // 邏輯 - 是/否/未知
    dropdownSingle: '_SYS_AK_8', // 下拉式選項 - 單選
    dropdownMultiple: '_SYS_AK_9', // 下拉式選項 - 多選
  },
  announcementLabelList: '_SYS_ANNOUNCEMENT_1',
  announcementTypeList: '_SYS_BU',
  announcementType: {
    draft: '_SYS_BU_1', // 草稿
    published: '_SYS_BU_2', // 已發布
  },
  inspectionAndAuditTypeList: '_SYS_BS',
  changeAndAuditTypeList: '_SYS_CT',
  inspectionAndAuditType: {
    aramarkIssueReporting: '_SYS_BS_8',
    aramarkGeneralInspection: '_SYS_BS_9',
    aramarkImmediateInspection: '_SYS_BS_10'
  },
  inspectionObjectTypeList: '_SYS_AA',
  inspectionObjectTypeOther: '_SYS_AA_Z',
  deviationTypeList: '_SYS_B1',
  inspectionRiskLevelList: '_SYS_AX',
  inspectionRiskRatingList: '_SYS_AX',
  inspectionRiskRating: {
    high: '_SYS_AX_1',
    medium: '_SYS_AX_2',
    low: '_SYS_AX_3',
  },
  inspectionIssueClassificationList: '_SYS_DC',
  inspectionIssueRecordStatusList: '_SYS_BE',
  inspectionIssueRecordStatus: {
    review: '_SYS_BE_4',
    underImprovement: '_SYS_BE_5',
    completed: '_SYS_BE_6',
    caseClosed: '_SYS_BE_7',
    invalid: '_SYS_BE_8',
    inProgress: '_SYS_BE_9'
  },
  correctiveActionModeList: '_SYS_CA_AM',
  correctiveActionMode: {
    assignTask: '_SYS_CA_AM_1',
    immediateCorrection: '_SYS_CA_AM_2',
  },
  // investigationCauseList: '_SYS_B5',
  investigationCauseList: '_SYS_WH_6',
  deviationSeverityList: '_SYS_B9',
  deviationSeverity: {
    compliance: '_SYS_B9_5',
    nonCompliance: '_SYS_B9_2',
  },
  inspectionMethodList: '_SYS_IM',
  inspectionMethod: {
    selectForms: '_SYS_IM_1',
    enterFindingsDirectly: '_SYS_IM_2'
  },
  taskSendingMethodList: '_SYS_B1C1',
  taskSendingMethod: {
    sendingByOrganization: '_SYS_B1C1_2',
    sendingByGroup: '_SYS_B1C1_3'
  },
  inspectionObjectStatusList: '_SYS_B6',
  inspectionObjectStatus: {
    incomplete: '_SYS_B6_1',
    completed: '_SYS_B6_2',
  },
  questionType: '_SYS_EL_1',
  trainingTaskType: '_SYS_EL_2',
  trainingTaskTypeList: {
    video: '_SYS_EL_2_1',
    test: '_SYS_EL_2_2',
    pdf: '_SYS_EL_2_3',
    ppt: '_SYS_EL_2_4',
    signature: '_SYS_EL_2_5',
  },
  lessonStatus: '_SYS_EL_3',
  lessonStatusList: {
    unPublished: '_SYS_EL_3_1',
    published: '_SYS_EL_3_2',
    invalid: '_SYS_EL_3_3'
  },
  studyStatus: '_SYS_EL_5',
  investigationReportStatus: '_SYS_WH_13',
  investigationReportStatusList: {
    un_reassigned: '_SYS_WH_13_1',//进行中
    reassigned: '_SYS_WH_13_2',//填写中
    reassign_verifing: '_SYS_WH_13_3',//待审核
    reassign_closed: '_SYS_WH_13_4',//已通过
  },
  lessonSource: '_SYS_LS_A',
  lessonSourceList: {
    company: '_SYS_LS_A_1',
    project: '_SYS_LS_A_2'
  },
  spaceLevel: '_SPACE_LV',
  serviceType: '_SYS_WH_14',
  spaceInspectionResult: '_SYS_WH_16',
  spaceInspectionMethod: '_SYS_WH_29',
  serviceTypeResult: '_SYS_WH_15',
  radarType: '_SYS_WH_14_2',
  trafficRootCause: '_SYS_WH_3_14',
  formStyle: '_SYS_FT',
  ase: {
    env: {
      lvl1: '_SYS_WH_14_2_1',
      lvl2: '_SYS_WH_14_2_2',
      lvl3: '_SYS_WH_14_2_3',
    },
    dlv: {
      lvl1: '_SYS_WH_14_3_1',
      lvl2: '_SYS_WH_14_3_2',
      lvl3: '_SYS_WH_14_3_3',
    },
    eqp: {
      lvl1: '_SYS_WH_14_4_1',
      lvl2: '_SYS_WH_14_4_2',
      lvl3: '_SYS_WH_14_4_3',
    },
    sqt: {
      lvl1: '_SYS_WH_14_5_1',
      lvl2: '_SYS_WH_14_5_2',
      lvl3: '_SYS_WH_14_5_3',
    }
  },
  examStatus: '_SYS_EL_4',
  exemptionReason: '_SYS_WH_17',
  injuredPersonStatus: '_SYS_HURT_STS',
  employeeClassification: '_SYS_AA',

  complianceDocumentClassificationTypeList: '_SYS_CD_A1',
  complianceDocumentClassificationType: {
    classification: '_SYS_CD_A1_1',
    document: '_SYS_CD_A1_2'
  },
  complianceDocumentStatusList: '_SYS_CD_A2',
  complianceDocumentStatus: {
    valid: '_SYS_CD_A2_1',
    invalid: '_SYS_CD_A2_2',
    inapplicable: '_SYS_CD_A2_3'
  },
  complianceDocumentRenewReasonList: '_SYS_CD_A3',
  complianceDocumentRenewReason: {
    expire: '_SYS_CD_A3_1',
    majorIssues: '_SYS_CD_A3_2'
  },
  testType: '_SYS_WH_21',
  paperType: {
      thory:'_SYS_WH_30_1',
      practice:'_SYS_WH_30_2'
  },// 试卷类型
  sequenceStatus: '_SYS_WH_22',
  sequenceStatusList: {
    pending: '_SYS_WH_22_1',
    live: '_SYS_WH_22_2',
    finished: '_SYS_WH_22_3',
  },
  contentTestType: '_SYS_WH_20',
  gradeStatus: '_SYS_WH_23',
  gradeStatusList: {
    unfinished: '_SYS_WH_23_1',
    finished: '_SYS_WH_23_2'
  },
  gradeResult: '_SYS_WH_24',
  gradeResultList: {
    passed: '_SYS_WH_24_1',
    failed: '_SYS_WH_24_2',
    pending: '_SYS_WH_24_3'
  },
  //微小项目
  microProjectType: '_SYS_WH_26',

  scoreType: '_INTEGRAL_TYPE',  //积分类型
  inspectionMethods:'_INSPECTION_METHOD',  //ASE检查方式

  // 目标与计划
  projectPlanListStatus:'_SYS_TP',//项目列表状态
  projectPlanStatus:'_SYS_PP',//项目状态
  projectPlanType:'_SYS_PPT',//计划类型
  departmentName:'_SYS_DN',//部门名称
  taskDistributeStatus:'_SYS_EL_3',//任务状态

};
